$primary-color: #009534; // Light Green
$secondary-color: #9D0034;
$tertiary-color: #125E1C;

$heading-font-color: $tertiary-color;
$base-font-color: #222;
$link-color: $secondary-color;
$border-color: #dbdbdb;

$search-home-bg-color: $primary-color;
$advantage-color: #598527;
$positive-color: green;
$negative-color: red;
$star-reviews-color: #898989;
$mobile-header-background: white;
$mobile-header-color:$primary-color;

$legenda-available-color: #39b54a;
$legenda-blocked-color: #bbb;

$body-bg: #FCFEFB;
$primary: #009534;
$secondary: #9D0034;
$tertiary:  #125E1C;

$call_to_action_bg: #125E1C;
$call_to_action_text: white;

$body-color: #222;


@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&display=swap');


$font-family-sans-serif: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$font-family-base: 'Open Sans';
$btn-font-family: 'Open Sans';
$headings-font-family: 'Open Sans';
$body-font-family: 'Open Sans';
$body-font-size: 1.1rem;
$body-line-height: 1.9rem;

@import "../../flean_base_2019/sass/imports";

.discounts-custom {
  .inner {
    > div {
      position:absolute;
      top: -7px;
      left:-10px;
      height:73px;
      width:193px;
      background-size:contain !important;
      background-repeat:no-repeat !important;
      z-index:0;
      .column &{
        top:65px;
      }
      @include media-breakpoint-down(lg){
        width:193px;
        top: -7px;
        left:-10px;
        .column &{
          top:5px;
          left:auto;
          right:5px;
          width:120px;
        }
      }
      &.nl{
        background:url(../images/aanbieding.png);
      }
      &.de{
        background:url(../images/angebot.png);
      }
      &.last_minute.de,
      &.last_minute.nl{
        background:url(../images/last-minute.png);
      }
    }
  }
}

.offers .offer .book button {
  background-color: $primary;
  border-color: $primary;
}

.paragraphs-header {
  h1.title,
  h2.title {
    font-weight: 200;
  }
}
.reviews-homepage {
  h2 {
    font-weight: 200;
  }
}
